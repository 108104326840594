
const template = document.createElement("template");
template.innerHTML += `
    <style>
        .box {
          display: block;
          position: relative;
          max-height: 180px;
          transition: max-height 0.6s cubic-bezier(0,1,0,1);
          
        }
        
        .box.gradient {
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), color-stop(0.3, rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        }
        
        .box.open {
            max-height: 100rem;
            transition: max-height 0.6s cubic-bezier(0.9, 0, 0.8, 0.2);
            -webkit-mask-image: unset;
        }
        
    </style>
    
    <div id="content" class="box">
    </div>
    
    
    <label id="read-more-button" class="daisy-btn daisy-btn-circle daisy-btn-xs daisy-swap daisy-swap-rotate shadow my-2 float-right" >
        <input id="read-more" type="checkbox" />
        <span class="daisy-swap-on">
            <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
        </span>
        <span class="daisy-swap-off">
            <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        </span>
    </label>
`.trim();

class Collapsible extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.mount();

        if (this.overflowing) {
            this.content?.classList.add("gradient");
            this.toggleCheckbox?.addEventListener("change", this.refresh)
        }
        else {
            this.buttonContainer?.remove()
        }
    }

    mount() {
        let node = template.content.cloneNode(true) as HTMLDivElement
        let contentSlot = node.querySelector('#content')!;
        contentSlot.append(...this.childNodes)
        this.appendChild(node);
        this.style.display = "block";
        this.style.overflow = "hidden";
        this.style.position = "relative";
    }

    get toggleCheckbox() {
        return this.querySelector<HTMLInputElement>('#read-more')
    }

    get content() {
        return this.querySelector<HTMLElement>("#content")
    }

    get buttonContainer() {
        return this.querySelector("#read-more-button")
    }

    refresh = () => {
        if (this.open) {
            this.content.classList.add("open")
        }
        else {
            this.content.classList.remove("open")
        }
    };



    get open() {
        return this.toggleCheckbox?.checked
    }

    get overflowing() {
        return this.offsetHeight < this.scrollHeight || this.offsetWidth < this.scrollWidth
    };
}

customElements.define("my-collapsible", Collapsible, );
